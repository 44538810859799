import React from "react";
import { useLocation } from "react-router-dom";
import "./app.css";
import { setDominantColor } from "@store/features/colorSlice";
import { useDispatch } from "react-redux";
import Router from "./routes";
// eslint-disable-next-line
export default function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const uidUserEntity = localStorage.getItem("Uid_Entitie_User");

    const [isLoading, setIsLoading] = React.useState(
        !(location.pathname === "/login" || location.pathname === "/")
    );

    dispatch(
        setDominantColor(
            localStorage.getItem("mainColor") !== null
                ? (localStorage.getItem("mainColor") as string)
                : "#323333"
        )
    );

    const fetchListUidEntityBeta = async () => {
        try {
            const url = `${
                process.env.REACT_APP_UUID_BETA
            }?cacheBuster=${Date.now()}`;
            const response = await fetch(`${url}`);
            if (!response.ok) {
                throw new Error(
                    `Erreur lors du chargement des données: ${response.status} ${response.statusText}`
                );
            }
            return await response.json();
        } catch (error) {
            // eslint-disable-next-line
            console.error(
                "Erreur lors du chargement de listUidEntity :",
                error
            );
            return [];
        }
    };

    React.useEffect(() => {
        if (
            uidUserEntity &&
            process.env.REACT_APP_BETA !== process.env.REACT_APP_PROD
        ) {
            fetchListUidEntityBeta().then((UidEntityBeta) => {
                const isUserBeta = UidEntityBeta.some(
                    (item: any) => item.uuidEntity === uidUserEntity
                );

                const redirectTo = isUserBeta
                    ? process.env.REACT_APP_BETA
                    : process.env.REACT_APP_PROD;

                if (redirectTo && redirectTo !== baseUrl) {
                    localStorage.clear();
                    const newUrl = `${redirectTo}`;
                    try {
                        window.location.replace(newUrl);
                    } catch (error) {
                        // eslint-disable-next-line
                        console.error("Erreur lors de la redirection :", error);
                    }
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [uidUserEntity]);

    return isLoading ? null : <Router />;
}
